import React from "react"
import styled from "styled-components"
import Image from 'gatsby-image'


const HeroTwoCenter = ({ blog, content, image, subtitle }) => {
  return (
    <HeroHeader>
      <HeroCenter>
        <HeroImageWrapper>
            {blog ? <HeroImage fluid = {image}> </HeroImage>: <HeroImage fluid={image.childImageSharp.fluid}></HeroImage> }   
        </HeroImageWrapper>
        <HeroTextWrapper>
          <HeroTitle>
            <Name>{content}</Name>
            <Underline></Underline>
          </HeroTitle>
          <TextDescriptionWrapper>
              <TextDescription>
                {subtitle}
              </TextDescription>
          </TextDescriptionWrapper>      
        </HeroTextWrapper>
      </HeroCenter>
    </HeroHeader>
  )
}



const HeroTextWrapper = styled.div`
  z-index: 10;
  height: 61.0vh;
  width: 100%;
  position: relative;
  text-align: center;
  top:-85%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const HeroTitle = styled.div`

  height: 10rem;
  width: fit-content;
  align-items: center;
  justify-content: center;  /* Add this line to align child elements to the left */
  /* left: 10%; */
  display: flex;
  flex-direction: column;
  position: relative;
`

const Underline = styled.section`
  width:10rem;
  max-width: 10rem;
  height: 0.25rem;
  justify-content: center;
  position: relative;
  background: ${({ theme }) => theme.colorPrimary5};
  z-index: 40;
  display: inline;
  /* left:1%; */

`


const TextDescription = styled.h3`
  width: 100%;
  margin: 0 auto;
  align-items: center;
  text-align: center;
  color: white;
  color: ${({ theme }) => theme.colorWhite};
  max-width:70rem;
`

const TextDescriptionWrapper = styled.div`
    justify-content: center; 
     /* Add this line to align child elements to the left */
    height: 10rem;
    /* margin-right:5rem; */
    margin-left: 2rem;
    margin-right:2rem;
    width: fit-content;
    max-width: 50rem;
    align-items: center;
    text-align: left;
    /* left: 10%; */
    position: relative;

`

const HeroImageWrapper = styled.div`

  z-index: 1;
  height: 80vh;
  width: 100%;
  /* position: relative; */
  text-align: center;
  color: white;
  margin: 0 auto;
  @media screen and (max-width: 799px) {
    height: 65vh;
  }
`
const Name = styled.h1`
  z-index: 10000;
  color: ${({ theme }) => theme.colorWhite};
 
`

const HeroImage = styled(Image)`
  height: 65vh;
  width: 100%;
  z-index: 1;
  filter: brightness(60%);

  @media screen and (max-width: 799px) {
    height: 50vh;
  }
`

const HeroHeader = styled.header`
  height: 65vh;
  margin: 0 auto;
  justify-content: center;
  background: ${({ theme }) => theme.background};
  
  @media screen and (max-width: 799px) {
    height: 50vh;
  }
`
const HeroCenter = styled.div`
  height: 80vh;
  width: 100%;
  position: relative;
  justify-content: center;
  @media screen and (max-width: 799px) {
    height: 66vh;
    /* background-color: red; */
  }
`








export default HeroTwoCenter