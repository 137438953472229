import React, { useState } from "react"
import Layout from "../components/Layout"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"
import Hero from "../components/HeroTwoCenter"
import { graphql } from "gatsby"

const Testimonios = ({ data }) => {
  const [render, setRender] = useState(false)
  const {
    allContentfulTestimonio: { edges: displayTestimonies },
    allContentfulTestimonioResult: { edges: displayTestimonioResult },
  } = data

  /* Watch for this */
  const { node_locale } = data.allContentfulTestimonio.edges[0].node

  let locale = node_locale
  let testimonios = ""

  locale === "en"
    ? (testimonios = "Testimonials")
    : (testimonios = "Testimonios")

  function click() {
    setRender(true)
  }

  return (
    <>
      <Layout>
        <Hero content={testimonios} image={data.file}></Hero>
        <Div>
          <Wrapper>
            {displayTestimonies.map((item, index) => {
              const { rawMarkdownBody } = item.node.into.childMarkdownRemark
              return (
                <div>
                  <ReactMarkdown>{rawMarkdownBody}</ReactMarkdown>
                </div>
              )
            })}
            <div>
              {render ? (
                displayTestimonioResult.map((item, index) => {
                  const { rawMarkdownBody } = item.node.into.childMarkdownRemark
                  return (
                    <div>
                      <ReactMarkdown>{rawMarkdownBody}</ReactMarkdown>
                    </div>
                  )
                })
              ) : (
                <Button onClick={() => click()}>Mas Testimonios</Button>
              )}
            </div>
          </Wrapper>
        </Div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query getTestimonio($locale: String) {
    allContentfulTestimonio(
      filter: { pagina: { eq: true }, node_locale: { eq: $locale } }
      sort: { fields: orden }
    ) {
      edges {
        node {
          id
          title
          orden
          pagina
          into {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          node_locale
        }
      }
    }

    allContentfulTestimonioResult: allContentfulTestimonio(
      filter: { pagina: { eq: false }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          id
          title
          orden
          pagina
          node_locale
          into {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
        }
      }
    }

    file(relativePath: { eq: "summer.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  margin: 0 auto;
`

const Div = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  background: ${({ theme }) => theme.colorGrey10};
`
const Wrapper = styled.section`
  background: ${({ theme }) => theme.colorGrey10};
  width: 90vw;
  max-width: 700px;
  margin: 4rem auto;
`

export default Testimonios
